<template>
  <a-upload
    :action="upLoadObj.action"
    :before-upload="beforeUpload"
    :show-upload-list="false"
    class="avatar-uploader"
    list-type="picture-card"
    @change="handleImgChange"
  >
    <slot :data="{ value, imgLoading }">
      <img v-if="value" :src="value" alt="avatar" class="img-style">
      <div v-else>
        <a-icon :type="imgLoading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </slot>
  </a-upload>
</template>

<script>
/**
 * @author  dengqingbin
 * @description 图片上传组件
 * 1.用于[用户管理编辑页面]：上传营业执照
 */
import {
  beforeUpload,
  getBaseCallback64,
  headers,
  upLoadObj
} from '@/utils/utils'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: String,
    }
  },
  computed: {
    // 支持 v-model 双向数据绑定，如果有新数据会通过 $emit 一个 input 事件进行修改 v-model 的值，也就是当前 value 值。
    // 写法是固定的，vue 自动处理，只管通过 $emit('input', newValue) 抛出去新的值即可。
    currentValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    currentValue: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        // 记录最新的值
        this.selectedValues = newValue
        // 判断当前的 value 值是否为 undefined， 如果是的话不用抛出去，要不然 form 标签就会走效验，并提示对应的错误了
        if (newValue !== undefined) {
          // v-decorator 会通过 change 事件接受新值
          this.$emit('change', newValue)
          this.$emit('ok', newValue)
        }
      }
    }
  },
  data() {
    return {
      headers,
      beforeUpload,
      upLoadObj,
      imgLoading: false
    }
  },
  methods: {
    handleImgChange(info) {
      console.log(info, info)
      if (info.file.status === 'uploading') {
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        // 1.回调函数可以带上 imageUrl 本地转换的路径
        getBaseCallback64(info.file.originFileObj, () => {
          this.$emit('changeImg', info.file.response.link)
          this.$emit('change', info.file.response.link)
          this.imgLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.img-style {
  width: 200px;
}
</style>
